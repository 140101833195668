/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import 'core-js/stable'
import 'regenerator-runtime/runtime'

require("@rails/ujs").start()
require("turbolinks").start()
require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// REACT
// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);


// BOOTSTRAP
import 'bootstrap/js/dist/util'
import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/button'
// import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/dropdown'
// import 'bootstrap/js/dist/modal'
// import 'bootstrap/js/dist/popover'
// import 'bootstrap/js/dist/scrollspy'
import 'bootstrap/js/dist/tab'
// import 'bootstrap/js/dist/toast'
// import 'bootstrap/js/dist/tooltip'


// FANCYBOX
import '@fancyapps/fancybox'


// APP
import carts from 'src/carts'
import forms from 'src/forms'
import customersFilter from 'src/customersFilter'
import scrollTop from 'src/scrollTop'
import generateCharts from 'src/charts'


jQuery.expr[':'].Contains = function(a, i, m) {
  return (a.textContent || a.innerText || '').replace(/\s+/g, '').replace(/[^a-zA-Z0-9-]/g, '').replace(/\-/g, '').toUpperCase().indexOf(m[3].replace(/\s+/g, '').replace(/[^a-zA-Z0-9-]/g, '').replace(/\-/g, '').toUpperCase()) >= 0
}

const ready = () => {
  carts()
  forms()
  scrollTop()
  generateCharts()
  customersFilter()

  $('[data-fancybox="photoItems"]').fancybox({
    buttons: ["slideShow", "close"],
    // protect against photo download
    protect: true,
    // disable URL updating on navigate
    hash: false,
    beforeClose: function(instance, current) {
      const thumbnailElement = $.fancybox.getInstance().current.opts.$orig
      const navbarHeight = $("nav.navbar").height()
      return $('html, body').animate({
        scrollTop: thumbnailElement.offset().top - navbarHeight - 15
      }, 300)
    }
  })

  $('[data-fancybox="shipmentDeliveryPhoto"]').fancybox({
    buttons: ["close"],
    // disable URL updating on navigate
    hash: false
  })

  $("img.jq_prevent_download").on({
    contextmenu: function(e) {
      e.preventDefault()
      return false
    },
    dragstart: function(e) {
      e.preventDefault()
      return false
    },
    touchend: function(e) {
      e.preventDefault()
      return false
    }
  })

}

$(document).on("turbolinks:load", (event) => {
  return ready()
})
