function carts() {
  if ($("#jq_cart_change_cart_pickup_location").length > 0) {
    $("#jq_cart_cart_pickup_locations").addClass("d-none")
  }
  $("#jq_cart_change_cart_pickup_location").on({
    click: function(e) {
      e.preventDefault()
      $("#jq_cart_cart_pickup_locations").removeClass("d-none")
      $("#jq_cart_current_pickup_location").addClass("d-none")
      $(this).parent().addClass("d-none")
      return false
    }
  })
  if ($("#jq_cart_add_new_address").length > 0) {
    $("#jq_cart_new_address").addClass("d-none")
  }
  $("#jq_cart_add_new_address").on({
    click: function(e) {
      e.preventDefault()
      $("#jq_cart_new_address").removeClass("d-none")
      $(".jq_cart_address_use_button").addClass("disabled")
      $(this).parent().parent().parent().addClass("text-muted")
      $(this).addClass("disabled")
      return false
    }
  })
  return $("#jq_cart_cancel_new_address").on({
    click: function(e) {
      e.preventDefault()
      $("#jq_cart_new_address").addClass("d-none")
      $(".jq_cart_address_use_button").removeClass("disabled")
      $("#jq_cart_add_new_address").removeClass("disabled")
      $("#jq_cart_add_new_address").parent().parent().parent().removeClass("text-muted")
      return false
    }
  })
}

export default carts
