import * as d3 from "d3"
import c3 from 'c3'

const chart_color_pattern = ["#43ac6a", "#E99002", "#5bc0de", "#F04124"]

const horizontal_bar_params = {
  size: {
    height: 150
  },
  data: {
    type: 'bar'
  },
  axis: {
    rotated: true,
    x: {
      show: false
    },
    y: {
      show: false
    }
  },
  color: {
    pattern: chart_color_pattern
  }
}

const vertical_category_bar_chart_params = {
  data: {
    type: 'bar',
    x: 'x'
  },
  axis: {
    x: {
      type: 'category'
    }
  },
  color: {
    pattern: chart_color_pattern
  }
}

const vertical_category_money_bar_chart_params = {
  data: {
    type: 'bar',
    x: 'x'
  },
  axis: {
    x: {
      type: 'category'
    },
    y: {
      tick: {
        format: d3.format("$s")
      }
    }
  },
  tooltip: {
    format: {
      value: d3.format('$,')
    }
  },
  color: {
    pattern: chart_color_pattern
  }
}

const category_spline_chart_params = {
  data: {
    type: 'bar',
    x: 'x'
  },
  axis: {
    x: {
      type: 'category'
    }
  },
  color: {
    pattern: chart_color_pattern
  }
}

const category_money_spline_chart_params = {
  data: {
    type: 'spline',
    x: 'x'
  },
  axis: {
    x: {
      type: 'category'
    },
    y: {
      tick: {
        format: d3.format("$s")
      }
    }
  },
  tooltip: {
    format: {
      value: d3.format('$,')
    }
  },
  color: {
    pattern: chart_color_pattern
  }
}

const pie_chart_params = {
  data: {
    type: 'pie'
  },
  color: {
    pattern: chart_color_pattern
  }
}

const money_pie_chart_params = {
  data: {
    type: 'pie'
  },
  tooltip: {
    format: {
      value: function(value, ratio, id, index) {
        return '$' + value
      }
    }
  },
  color: {
    pattern: chart_color_pattern
  }
}

function generateDashboardOrderStatusChart() {
  const order_status_chart_params = $.extend(true, {}, horizontal_bar_params)
  order_status_chart_params.bindto = "#dashboard_order_status_chart"
  order_status_chart_params.data.columns = gon.order_status_data
  order_status_chart_params.data.groups = gon.order_status_groups

  if (gon.order_status_data) {
    c3.generate(order_status_chart_params)
  }
}

function generateDashboardRevenueChart() {
  const project_revenue_chart_params = $.extend(true, {}, vertical_category_money_bar_chart_params)
  project_revenue_chart_params.bindto = "#dashboard_project_revenue_chart"
  project_revenue_chart_params.data.columns = gon.project_revenue_data
  project_revenue_chart_params.data.onclick = (d, element) => {
    Turbolinks.visit(gon.project_paths[d.index])
  }

  if (gon.project_revenue_data) {
    c3.generate(project_revenue_chart_params)
  }
}

function generateClientProjectsRevenueChart() {
  const project_revenue_chart_params = $.extend(true, {}, category_money_spline_chart_params)
  project_revenue_chart_params.bindto = "#client_projects_revenue_chart"
  project_revenue_chart_params.size = { height: 175 }
  project_revenue_chart_params.legend = { position: 'inset' }
  project_revenue_chart_params.data.columns = gon.project_revenue_data

  project_revenue_chart_params.data.onclick = (d, element) => {
    Turbolinks.visit(gon.project_paths[d.index])
  }

  if (gon.project_revenue_data) {
    c3.generate(project_revenue_chart_params)
  }
}

function generateCharts() {
  if ($("#dashboard_order_status_chart").length > 0) generateDashboardOrderStatusChart()
  if ($("#dashboard_project_revenue_chart").length > 0) generateDashboardRevenueChart()
  if ($("#client_projects_revenue_chart").length > 0) generateClientProjectsRevenueChart()
}

export default generateCharts
