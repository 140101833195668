function forms() {
  $('form').on('click', '.remove_fields', function(event) {
    event.preventDefault()
    $(this).prev('input[type=hidden]').val('1')
    $(this).closest(".field_set").hide()
  })

  $('form').on('click', '.add_fields', function(event) {
    event.preventDefault()
    const time = new Date().getTime()
    const regexp = new RegExp($(this).data('id'), 'g')
    $(this).closest(".fields_container").append($(this).data('fields').replace(regexp, time))
  })
}

export default forms
