function customersFilter() {
  const input = $('input#customers-filter')
  if (input) {
    return $(input).change(() => {
      const filter = $(input).val()
      if (filter) {
        $("table#customers tbody").find('tr:not(:Contains(' + filter + '))').fadeOut(300)
        $("table#customers tbody").find('tr:Contains(' + filter + ')').fadeIn(300)
      } else {
        $("table#customers tbody").find('tr').fadeIn(300)
      }
    }).keyup(() => {
      $(this).change()
    })
  }
}

export default customersFilter
