import React, { Component } from 'react'
import PropTypes from 'prop-types'


class ItemUploadPreview extends React.Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    const saveProgressBar =
      <div
        className={`progress-bar w-50 ${this.props.item.save_status === "success" ? "bg-success" : this.props.item.save_status === "failed" ? "bg-danger" : "progress-bar-striped progress-bar-animated bg-secondary" }`}
        role="progressbar"
        aria-valuenow="50"
        aria-valuemin="0"
        aria-valuemax="100"
      >
        <span>
          Save
          {this.props.item.save_status === "success" ? <i className="fas fa-check ml-1"></i> : null}
        </span>
      </div>

    return (
      <div className="col-12 col-sm-6 col-md-4 col-xl-3">
        <div className="card my-3 shadow">
          <img
            src={this.props.item.image}
            className="card-img-top"
          />

          <div className="card-body text-center">
            <h5 className="card-title">{this.props.item.name}</h5>

            <div className="progress">
              <div
                className={`progress-bar w-50 ${this.props.item.upload_status === "success" ? "bg-success" : this.props.item.upload_status === "failed" ? "bg-danger" : "progress-bar-striped progress-bar-animated bg-secondary" }`}
                role="progressbar"
                aria-valuenow="50"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span>
                  Upload
                  {this.props.item.upload_status === "success" ? <i className="fas fa-check ml-1"></i> : null}
                </span>
              </div>
              {this.props.item.upload_status === "" ? null : saveProgressBar}
            </div>

          </div>

        </div>
      </div>
    )
  }
}

ItemUploadPreview.defaultProps = {
  item: { name: '', image: '', upload_status: '', save_status: '' },
}

ItemUploadPreview.propTypes = {
  item: PropTypes.object,
}

export default ItemUploadPreview
