import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ax from 'src/utilities/axios'

import Uppy from '@uppy/core'
import AwsS3Multipart from '@uppy/aws-s3-multipart'
import ThumbnailGenerator from '@uppy/thumbnail-generator'
import {
  DragDrop,
  StatusBar,
} from '@uppy/react'
// import Tus from '@uppy/tus'

import ItemUploadPreview from './ItemUploadPreview'



class ItemBulkUpload extends React.Component {

  // React setup
  constructor(props) {
    super(props)
    this.state = {
      items: [],

      description: '',
      category: '',
      showInStore: false,
      pickup: false,
      preOrder: false,
      quickbooksId: '',
    }
    this.setupUppy()

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    })
  }

  setupUppy = () => {
    this.uppy = new Uppy({
      autoProceed: true
    })
    .use(ThumbnailGenerator, {
      thumbnailWidth: 400,
    })
    .use(AwsS3Multipart, {
      companionUrl: '/', // will call the presign endpoint on `/s3/params`
      limit: 5,
      retries: [0, 1500, 3000, 10000],
    })

    // this.uppy.use(Tus, { endpoint: '/upload' })

    this.uppy.on('thumbnail:generated', (file, preview) => {
      this.setState(state => {
        const items = state.items.concat({ name: file.name, image: preview, upload_status: '', save_status: '' })
        return {
          items,
        }
      })
    })

    // TRIGGERED FOR EACH FILE
    this.uppy.on('upload-failed', (file, response) => {
      this.changeItemStatus(file.name, "upload", "failed")
    })

    // TRIGGERED FOR EACH FILE
    this.uppy.on('upload-success', (file, response) => {

      // construct uploaded file data in the format that Shrine expects
      const uploadedFileData = {
        id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        }
      }

      this.handleUploadedFile(uploadedFileData)
    })

    // TRIGGERED WHEN ALL FILES ARE FINISHED
    // this.uppy.on('complete', (result) => {
    //   const url = result.successful[0].uploadURL
    // })
  }

  changeItemStatus = (fileName, status, value) => {
    const items = this.state.items
    const item = _.find(items, { 'name': fileName })

    if (item) {
      const index = _.indexOf(items, item)

      if (status === "upload") {
        item.upload_status = value
      } else if (status === "save") {
        item.save_status = value
      }

      items.splice(index, 1, item),

      this.setState(state => {
        return {
          items,
        }
      })

    }
  }

  handleUploadedFile = (uploadedFileData) => {
    this.changeItemStatus(uploadedFileData.metadata.filename, "upload", "success")

    ax.post(this.props.item_post_url,
    {
      item: {
        name: uploadedFileData.metadata.filename,
        photo: uploadedFileData,
        description: this.state.description,
        category_id: this.state.category,
        show_in_store: this.state.showInStore,
        pickup: this.state.pickup,
        pre_order: this.state.preOrder,
        quickbooks_id: this.state.quickbooksId,
      },
    }
  )
    .then(data => {
      this.changeItemStatus(uploadedFileData.metadata.filename, "save", "success")
    })
    .catch(error => {
      console.error("Error occurred trying to upload item", uploadedFileData.metadata.filename)
      this.changeItemStatus(uploadedFileData.metadata.filename, "save", "failed")
    })

  }

  render() {
    const dragDrop =
      <div>
        <DragDrop
          uppy={this.uppy}
          locale={{
            strings: {
              // Text to show on the droppable area.
              // `%{browse}` is replaced with a link that opens the system file selection dialog.
              dropHereOr: 'Drop here or %{browse}',
              // Used as the label for the link that opens the system file selection dialog.
              browse: 'browse'
            }
          }}
        />
        <StatusBar
          uppy={this.uppy}
          hideUploadButton
          hideAfterFinish={false}
          showProgressDetails
        />

        <div className="row">
          {this.state.items.map((item) =>
            <ItemUploadPreview item={item} key={item.image} />
          )}
        </div>

      </div>

    const disabled =
      <div className="card my-3 shadow">
        <div className="card-body">
          <p className="card-text">A category and Quickbooks item must be selected</p>
        </div>
      </div>

    return (
      <div>
        <form>
          <div className="form-group row">
            <label className="col-form-label col-md-2" htmlFor="category">Category</label>
            <div className="col-md-10">
              <select className="form-control" name="category" id="category" value={this.state.category} onChange={this.handleInputChange}>
                <option value=""></option>
                {this.props.categories.map((category) =>
                  <option key={category.id} value={category.id}>{category.name}</option>
                )}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-md-2" htmlFor="quickbooksId">Quickbooks</label>
            <div className="col-md-10">
              <select className="form-control" name="quickbooksId" id="quickbooksId" value={this.state.quickbooksId} onChange={this.handleInputChange}>
                <option value=""></option>
                {this.props.quickbooks_items.map((item) =>
                  <option key={item[1]} value={item[1]}>{item[0]}</option>
                )}
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label className="col-form-label col-md-2" htmlFor="description">Description</label>
            <div className="col-md-10">
              <textarea
                name="description"
                id="description"
                className="form-control"
                onChange={this.handleInputChange}
                value={this.state.name}
              ></textarea>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-10 offset-md-2">
              <div className="form-check">
                <input
                  className="form-check-input"
                  name="showInStore"
                  id="showInStore"
                  type="checkbox"
                  checked={this.state.showInStore}
                  onChange={this.handleInputChange}
                />
                <label className="form-check-label" htmlFor="showInStore">Show in store</label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-10 offset-md-2">
              <div className="form-check">
              <input
                  className="form-check-input"
                  name="preOrder"
                  id="preOrder"
                  type="checkbox"
                  checked={this.state.preOrder}
                  onChange={this.handleInputChange}
                />
                <label className="form-check-label" htmlFor="preOrder">Pre order</label>
              </div>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-md-10 offset-md-2">
              <div className="form-check">
              <input
                  className="form-check-input"
                  name="pickup"
                  id="pickup"
                  type="checkbox"
                  checked={this.state.pickup}
                  onChange={this.handleInputChange}
                />
                <label className="form-check-label" htmlFor="pickup">Pickup</label>
              </div>
            </div>
          </div>
        </form>


        { (this.state.category.length === 0 || this.state.quickbooksId.length === 0) ? disabled : dragDrop }

      </div>
    )
  }
}

ItemBulkUpload.defaultProps = {
  item_post_url: '',
  categories: [],
  quickbooks_items: [],
}

ItemBulkUpload.propTypes = {
  item_post_url: PropTypes.string,
  categories: PropTypes.array,
  quickbooks_items: PropTypes.array,
}

export default ItemBulkUpload
